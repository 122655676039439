<template>
  <div>
    <Resource
      :form-component="() => import('@/components/forms/ProductProductActionForm.vue')"
      :index-request="indexHandler"
      :meta="{name: $tc('productAction.title', 1), namePlural: $tc('productAction.title', 2)}"
      :model-type="modelType"
      :show-request="showHandler"
      :table-content="tableContent"
      :update-request="updateHandler"
      :create-request="createHandler"
      :delete-request="deleteHandler"
      :can-add="can('client.product.product-action.all')"
      :can-delete="can('client.product.product-action.all')"
      :can-update="can('client.product.product-action.all')"
    />
  </div>
</template>

<script lang="js">
import { index, show, update, remove, create } from '@/api/endpoints/productProductAction.js';
import ProductProductAction from '@/application/models/productProductAction.js';
import Resource from '@/components/Resource.vue';
import eventBus from '@/eventBus.js';
import { mapGetters } from "vuex";

export default {
  name: 'ProductProductActionResource',
  components: {
    Resource,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("profile", ["can"]),
    showHandler: () => show,
    updateHandler: () => update,
    deleteHandler: () => remove,
    modelType: () => ProductProductAction,
    tableContent() {
      return [
        {
          text: this.$t('productAction.fields.productAction'),
          align: 'left',
          sortable: true,
          value: 'productActionName',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'client.index' },
        text: this.$tc('client.title', 2),
      },
      {
        exact: true,
        to: { name: 'client.connectors' },
        text: this.client.name,
      },
      {
        exact: true,
        to: { name: 'client.products' },
        text: this.$tc('product.title', 2),
      },
      {
        exact: true,
        to: {
          name: 'product.actions',
          params: { productId: this.product.id },
        },
        text: this.product.name,
      },
    ]);
  },
  methods: {
    indexHandler() {
      return index(this.$route.params.productId, ...arguments);
    },
    createHandler() {
      return create(this.$route.params.productId, ...arguments);
    },
  },
};
</script>
