import Model from './model.js';

class ProductProductAction extends Model {
  id = 0;
  productAction = null;
  settings = {};

  mapForRequest() {
    return {
      productActionId: this.productAction?.id,
      settings: this.settings,
    };
  }
}

export default ProductProductAction;
