import { getPaginated } from '@/api/implementation/app/wrapper.js';
import { destroy, get, post, put } from '@/api/implementation/app';

function index(productId, page, perPage, search, sortBy, descending, params) {
  return getPaginated(`product/${productId}/product-action`, page, perPage, search, sortBy, descending, params);
}

function show(connectorId) {
  return get(`product/product-action/${connectorId}`);
}

function create(productId, productAction) {
  return post(`product/${productId}/product-action`, productAction);
}

function update(productAction) {
  return put(`product/product-action/${productAction.id}`, productAction);
}

function remove(connectorId) {
  return destroy(`product/product-action/${connectorId}`);
}

function downloadCsv(clientId) {
  return get(`client/${clientId}/product/csv-export`, {
    responseType: 'blob',
  });
}

function uploadCsv(clientId, file) {
  return post(`client/${clientId}/product/csv-import`, {
    file
  });
}

export {
  index,
  show,
  create,
  update,
  remove,
  downloadCsv,
  uploadCsv,
};
